.dialog-gallery {
    .img-holder {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-top: 60px;

        img {
            margin: 0px;
            width: 100%;

            @media screen and (min-width: 800px) {
                width: 25%;


            }
        }
    }
}