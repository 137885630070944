@import '../../colorScheme.scss';

.card {
    height: 400px;
    width: 280px;

    box-shadow: 0px 3.78437px 37.8437px rgba(0, 0, 0, 0.25);

    .icon-holder {
        height: 50%;
        background-color: $secondary;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 5px 5px 0px 0px;

        .icon {
            height: 90px;

        }

        h2 {
            font-size: 24px;
            font-weight: bold;
            max-width: 80%;
            text-align: center;
        }
    }

    .content {
        height: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0px 0px 5px 5px;
        background-color: $bgColor;
        flex-direction: column;
        font-size: 20px;

        table {
            td {
                vertical-align: top;
            }
        }
    }
}