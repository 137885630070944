* {
    margin: 0;
    padding: 0;

}

@import './colorScheme.scss';


html {
    scroll-behavior: smooth;


    @media screen and (min-width: 2000px) {
        display: flex;
        justify-content: center;
    }
}

body {
    background: $bgColor;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    max-width: 2000px;
}

$break-medium: 700px;
$break-almost-large:1000px;
$break-large: 1500px;
$break-ultralarge:2000px;

.half-bg-holder {
    position: relative;

    .half-bg:after {
        content: "";
        display: inline-block;
        width: 110%;
        height: 40%;
        background-color: $secondary;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, +30%);
        z-index: -1;

    }
}

#landing-page {

    height: 100vh;
    position: relative;
    max-height: 1080px;
    // min-height: 500px;

    .tittle {
        position: absolute;
        bottom: 20%;
        left: 50%;
        transform: translate(-50%, -0%);
        text-align: center;
        width: auto;


        @media screen and (min-width: $break-medium) {
            top: 40%;
            left: 10%;
            transform: translate(0%, -50%);
            text-align: left;
        }

        @media screen and (min-width: $break-almost-large) {
            top: 50%;
            left: 10%;
            transform: translate(0%, -50%);
            text-align: left;
        }

        @media screen and (min-width: $break-large) {
            top: 50%;
            left: 10%;
            transform: translate(0%, -50%);
            text-align: left;
        }

        @media screen and (max-height:500px) and (max-width:$break-medium) {
            top: 20%;
        }



        h1 {
            font-size: 40px;

            @media screen and (min-width: $break-medium) {
                font-size: 60px;

            }

            @media screen and (min-width: $break-large) {
                font-size: 95px;
            }
        }

        h2 {
            font-size: 24px;
            margin-bottom: 10px;

            @media screen and (min-width: $break-large) {
                font-size: 60px;
            }

            font-weight: normal;
        }
    }

    .icon-holder {
        position: absolute;
        z-index: -1;
        width: 200px;
        right: 50%;
        top: 30%;
        transform: translate(+50%, -50%) scale(0.4);

        @media screen and (max-height:500px) {
            display: none;
        }

        @media screen and (min-width: 365px) {
            transform: translate(+50%, -50%) scale(0.6);
        }

        @media screen and (min-width: $break-medium) {
            right: 20%;
            top: 60%;
            transform: translate(0%, -50%) scale(0.8);
        }

        @media screen and (min-width: $break-almost-large) {
            right: 20%;
            top: 50%;
            transform: translate(0%, -50%) scale(0.9);
        }

        @media screen and (min-width: $break-large) {
            right: 20%;
            top: 50%;
            transform: translate(0%, -50%) scale(1);
        }

        .icons {
            position: relative;
            width: 250px;
            height: 250px;

            #iconTractor {
                position: absolute;
                left: -200px;
                top: 0px;
                width: 350px;
            }

            #iconCar {
                position: absolute;
                left: 70px;
                top: -60px;
                width: 250px;
                z-index: -1;
            }

            #iconMoto {
                position: absolute;
                left: +100px;
                top: +140px;
                width: 300px;
            }
        }
    }

    .icon-tittle {
        position: absolute;
        bottom: 30px;
        left: 50%;
        transform: translate(-50%, 0%);
    }
}

#about-us-services {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    @media screen and (min-width: $break-large) {
        flex-wrap: nowrap;
    }

    .section {

        position: relative;

        margin: 20px;
        margin-bottom: 30px;
        margin-top: 30px;
        width: 100%;
        padding: 20px;

        @media screen and (min-width: $break-medium) {}

        @media screen and (min-width: $break-almost-large) {
            width: 400px;
            margin: 50px;
            margin-bottom: 30px;
            margin-top: 30px;
            padding: 0px;
        }

        @media screen and (min-width: $break-large) {
            width: 500px;
            margin: 150px;
            margin-bottom: 30px;
        }


        .contact-button {
            margin-top: 10px;
        }

        h2 {
            font-size: 60px;
            margin-bottom: 20px;
        }


    }

    .services-list {
        display: flex;
        align-items: center;
        padding-bottom: 5px;
        padding-top: 5px;

        .big-number {
            font-size: 60px;
            font-weight: bold;
            display: block;
            margin-right: 20px;

        }

        .list-text {
            display: block;

            width: 100%;
        }

        .conected-list {

            list-style-type: none;


            li {
                /* You need to turn on relative positioning so the line is placed relative to the item rather than absolutely on the page */
                position: relative;

                /* Use padding to space things out rather than margins as the line would get broken up otherwise */
                margin: 0;
                padding-bottom: 1em;
                padding-left: 20px;
            }

            /* The actual line being placed before each list item, tweak width and color as appropriate */
            li:before {
                background-color: rgb(0, 0, 0);
                width: 2px;
                content: '';
                position: absolute;
                top: 0px;
                bottom: 0px;
                left: 5px;
            }

            /* Start the line further down on the first list item */
            li:first-child:before {
                top: 15px;
            }

            /* Stop the line short on the final list item */
            li:last-child:before {
                height: 6px;
            }

            /* Styles for the SVG bullet points */
            .bullet {
                margin-left: -20px;
                width: 12px;
                fill: rgb(0, 0, 0);
                float: left;
                padding-right: 10px
            }

            .bullet.big {
                width: 16px;
                margin-left: -22px;
                padding-right: 8px
            }
        }
    }
}

.garage-section {
    display: flex;
    justify-content: center;
    margin-bottom: 220px;
    margin-top: 30px;

    @media screen and (min-width: $break-almost-large) {
        margin-bottom: 70px;
    }

    .holder {
        background-color: $secondary;
        position: relative;

        box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.25);

        width: 100%;
        height: 360px;

        @media screen and (min-width: $break-almost-large) {
            border-radius: 5px;
            margin-right: 0px;
            height: 450px;
            width: 730px;
        }

        @media screen and (min-width: $break-large) {
            margin-right: 80px;
        }

        h2 {
            font-size: 30px;

            position: absolute;
            left: 50%;
            top: 20px;
            text-align: center;
            transform: translate(-50%, 0%);

            @media screen and (min-width: $break-almost-large) {
                background-color: #EDAF3F;
                left: 0px;
                top: 50px;
                transform: translate(0%, 0%);
                padding-left: 30px;
                padding-right: 30px;
                text-align: left;
            }

        }

        .text {
            position: absolute;
            width: 80%;
            left: 50%;
            top: 110px;
            text-align: center;
            transform: translate(-50%, 0%);
            border-top: 2px solid black;
            padding-top: 10px;

            @media screen and (min-width: $break-medium) {
                width: 50%;
            }

            @media screen and (min-width: $break-almost-large) {
                padding-top: 0px;
                border-top: none;
                border-left: 2px solid black;
                left: 50px;
                top: 200px;
                padding-left: 20px;
                transform: translate(0%, 0%);
                width: 250px;
                text-align: left;
            }
        }

        .image {
            background-image: url('./components/imgs/car_prev.png');
            background-size: cover;
            background-position: center;
            position: absolute;
            box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.25);
            border-radius: 5px;
            bottom: 0px;
            width: 90vw;
            height: 300px;
            left: 50%;
            transform: translate(-50%, +60%);
            max-width: 450px;

            @media screen and (min-width: $break-almost-large) {
                right: 0px;
                transform: translate(0%, -50%);
                top: 50%;
                width: 496px;
                height: 300px;
            }

            @media screen and (min-width: $break-large) {
                transform: translate(25%, -50%);
            }

            .button-holder {
                width: 100%;
                height: 100%;
                // background: black;
                position: relative;

                button {
                    position: absolute;
                    left: 50%;
                    transform: translate(-50%, 0%);
                    bottom: 60px;
                }
            }
        }

    }
}

.card-holder {
    display: flex;
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;

    .card {
        margin: 30px;
        min-width: 250px;
        border-radius: 5px;

        p {
            max-width: 220px;
            font-size: 16px;
        }

        .note {
            border-top: 1px solid;
            margin-top: 10px;
            padding-top: 5px;
            max-width: 220px;
            font-size: 13px;
            color: #797979;
            width: 220px;
        }

        table {
            max-width: 220px;
            padding-right: 20px;
            // font-size: 16px;

            .left-part {

                text-align: right;
                padding-right: 10px;
                font-weight: bold;

            }

            td {
                min-width: 50px;
                font-size: 18px;

                span {
                    font-size: 10px;
                    vertical-align: top;
                    color: #797979;
                }
            }
        }

    }


}

.discount {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 50px;

    .text {
        box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        padding: 50px;
        text-align: center;
        max-width: 500px;
        font-size: 22px;
    }
}

#information {
    width: 100%;


    @media screen and (min-width: $break-ultralarge) {
        display: flex;
        //justify-content: center;
        flex-wrap: wrap;
    }

    .heading {

        margin-top: 50px;
        margin-left: 20px;
        font-size: 45px;



        @media screen and (min-width: $break-medium) {
            font-size: 60px;
            margin-left: 200px;
        }

        @media screen and (min-width: $break-ultralarge) {
            width: 100%;
        }
    }

    .icon-list-holder {
        margin-top: 30px;
        display: inline-flex;
        float: left;
        justify-content: center;
        border-right: none;


        @media screen and (min-width: $break-large) {
            width: 60vw;
            border-right: 1.0707px solid #000000;
        }

        @media screen and (min-width: $break-large) {
            max-width: 1400px;
        }

        .list-text {
            padding: 10px;
            padding-right: 15px;
        }

        .expantion-panel {
            background-color: none;
            border-bottom: black 2px solid;
            margin: 10px;
            padding-top: 10px;
            padding-bottom: 10px;

            @media screen and (min-width: $break-medium) {
                margin: 0px;
                padding: 10px;
                margin-left: 30px;
                margin-right: 30px;
            }
        }
    }

    .final-exam {
        float: left;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        width: 100%;
        margin-top: 50px;

        @media screen and (min-width: $break-large) {

            height: 900px;
            margin-top: 0px;
        }

        @media screen and (min-width: $break-large) {
            max-width: 590px;
        }

        .exam-icon {
            margin-left: 70px;
            margin-bottom: 30px;
        }

        .exam-heading {
            margin-left: 20px;
            font-size: 30px;

            @media screen and (min-width: $break-medium) {
                margin-left: 50px;
            }

            @media screen and (min-width: $break-large) {
                font-size: 20px;
                margin-left: 50px;
            }

        }

        p {
            margin: 10px;
            margin-left: 20px;
            padding-right: 20px;

            @media screen and (min-width: $break-medium) {
                margin-left: 50px;
                padding-right: 50px;
            }

            @media screen and (min-width: $break-large) {
                padding-right: 0px;
            }
        }

        table {
            margin-left: 20px;
            padding-right: 20px;

            @media screen and (min-width: $break-medium) {
                margin-left: 50px;

            }

            td {
                padding: 5px;
            }
        }
    }
}

#contacts {

    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    .contact-info {

        width: auto;
        margin-right: 0px;
        padding-left: 5px;
        padding-right: 5px;

        @media screen and (min-width: $break-almost-large) {
            width: 450px;
            //margin-left: 20px;
        }

        @media screen and (min-width: $break-large) {
            margin-right: 100px;

        }



        .heading {
            h2 {

                font-size: 50px;
                margin-left: 20px;

                @media screen and (min-width: $break-medium) {
                    margin-left: 0px;
                }
            }

            h3 {
                margin-top: 30px;
                font-size: 30px;
                font-weight: bold;
            }

            margin-top: 100px;

        }

        h3 {
            font-size: 32px;
            font-weight: normal;
        }

        table {
            width: 100%;

            td {
                padding: 5px;
                padding-left: 0px;
                vertical-align: top;
            }
        }

        .section {
            max-width: 450px;
            border-bottom: 1px black solid;
            padding-bottom: 18px;
            padding-top: 18px;
            font-size: 20px;

            .text-icon {
                transform: translate(0%, 18%);
            }
        }
    }

    .contact-form {
        background: #F0F0F0;
        margin-left: 0px;
        border-radius: 5px;
        box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.25);
        padding: 20px;
        width: 80vw;
        margin-top: 50px;


        @media screen and (min-width: $break-almost-large) {
            width: 480px;
            //margin-right: 20px;
            margin-left: 50px;
        }


    }
}

#footer {
    margin-top: 100px;
    height: 200px;
    background-color: #EBEBEB;
    display: flex;
    align-items: center;
    justify-content: center;

    .text {
        text-align: center;
    }
}