@import '../../colorScheme.scss';

.navbar {
    $break-point: 700px;

    position: fixed;
    width: 100vw;
    transition: top 0.4s, background-color 0.2s;
    background-color: $secondary;
    height: 50px;
    z-index: 500;
    left: 0px;

    .container {


        .heading {
            position: absolute;
            display: inline-block;
            font-size: 20px;
            left: 20px;
            top: 50%;
            transform: translate(0%, -50%);

            a {
                text-decoration: none;
                color: rgb(0, 0, 0);
            }
        }

        .navigation {
            position: absolute;

            right: 15px;
            top: 50%;
            transform: translate(0%, -50%);

            .menuButtons {
                display: none;

                @media screen and (min-width: $break-point) {
                    display: inline-block;
                }
            }

            .menuButton {
                display: inline-block;

                @media screen and (min-width: $break-point) {

                    display: none;
                }
            }
        }
    }



}

.MuiDrawer-root {
    .MuiPaper-root {
        background-color: $secondary !important;

    }
}